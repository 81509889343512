* { box-sizing: border-box; }

body { font-family: sans-serif; }

.scene {
    width: 200px;
    height: 200px;
    border: 1px solid #CCC;
    margin: 40px;
    perspective: 400px;
}

.cube {
    width: 200px;
    height: 200px;
    position: relative;
    transform-style: preserve-3d;
    transform: translateZ(-100px);
    transition: transform 0.5s;
}

.cube.show-front  { transform: translateZ(-100px) rotateY( -10deg) rotateX(-10deg);}

.cube.show-top1    { transform: translateZ(-100px) rotateX( -100deg);}
.cube.show-top2    { transform: translateZ(-200px) rotateX( -185deg);}
.cube.show-top3    { transform: translateZ(-300px) rotateX( -260deg);}
.cube.show-top4    { transform: translateZ(-400px) rotateX( -340deg);}

.cube.show-left1   { transform: translateZ(-100px) rotateY( 100deg) rotateX(-15deg);}
.cube.show-left2   { transform: translateZ(-100px) rotateY( 200deg) rotateX(-15deg);}
.cube.show-left3   { transform: translateZ(-100px) rotateY( 300deg) rotateX(-15deg);}
.cube.show-left4   { transform: translateZ(-100px) rotateY( 400deg) rotateX(-15deg);}

.cube.show-right1  { transform: translateZ(-100px) rotateY( -100deg) rotateX(-15deg);}
.cube.show-right2  { transform: translateZ(-100px) rotateY( -180deg) rotateX(-15deg);}
.cube.show-right3  { transform: translateZ(-100px) rotateY( -260deg) rotateX(-15deg);}
.cube.show-right4  { transform: translateZ(-100px) rotateY( -340deg) rotateX(-15deg);}

.cube.show-bottom1 { transform: translateZ(-100px) rotateX( 100deg);}
.cube.show-bottom2 { transform: translateZ(-100px) rotateX( 185deg);}
.cube.show-bottom3 { transform: translateZ(-100px) rotateX( 260deg);}
.cube.show-bottom4 { transform: translateZ(-100px) rotateX( 340deg);}

.cube__face {
    position: absolute;
    width: 200px;
    height: 200px;
    border: 4px solid white;
    font-size: 60px;
    font-weight: bold;
    color: white;
    justify-content: center;
    display: inline-flex;
    align-items: center;
}

.cube__face--front  { background: #FAAE9F; }
.cube__face--right  { background: #FAAE9F; }
.cube__face--back   { background: #FAAE9F; }
.cube__face--left   { background: #FAAE9F; }
.cube__face--top    { background: #FAAE9F; }
.cube__face--bottom { background: #FAAE9F; }

.cube__face--front  { transform: rotateY(  0deg) translateZ(100px); }
.cube__face--right  { transform: rotateY( 90deg) translateZ(100px); }
.cube__face--back   { transform: rotateY(180deg) translateZ(100px); }
.cube__face--left   { transform: rotateY(-90deg) translateZ(100px); }
.cube__face--top    { transform: rotateX( 90deg) translateZ(100px); }
.cube__face--bottom { transform: rotateX(-90deg) translateZ(100px); }

label { margin-right: 10px; }

.btn {
    float: left;
    position: relative;
    top: 0;
    cursor: pointer;
    background: white;
    border-radius: 15px;
    box-shadow: 0px 4px rgba(0, 0, 0, 0.2);
    margin: 0 7px 11px 0;
    color: #888;
    text-align: center;
    user-select: none;
    transition: all 0.2s ease;
}

.btn-small {
    line-height: 36px;
    width: 78px;
    height: 36px;
}

.btn-medium {
    line-height: 45px;
    width: 134px;
    height: 45px;
    font-size: 120%;
}

.btn-large2 {
    line-height: 55px;
    width: 250px;
    height: 55px;
    font-size: 90%;
}

.btn-large {
    line-height: 80px;
    width: 100%;
    height: 85px;
    font-size: 160%;
}

.btn-square {
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 55px;
    width: 150px;
    height: 150px;
    font-size: 90%;
    transition: all 0.2s ease;
    cursor: pointer;
}

.btn-square:active {
    box-shadow: 0px 0px #9624ed;
    top: 4px;
}

.square-bg-purple {
    background: #EEB189;
    box-shadow: 0px 10px 0px 0px dimgray;
    border-radius: 5px;
}

.square-bg-dark-purple {
    background: #AAD4E0;
    box-shadow: 0px 10px 0px 0px #86A5D4;
    border-radius: 5px;
}

.context-style {
    display: flex;
    justify-content: center;
}

.context-margin-style {
    display: flex;
    justify-content: center;
    padding: 30px;
}

/*==========  Different button styles  ==========*/

.submit {
    background: #FAAE9F;
    box-shadow: 0px 10px gray;
    /*color: #687e00;*/
    color: white;
}
.submit:hover {
    background: #bf6bff;
    box-shadow: 0px 10px #9624ed;
    /*color: #687e00;*/
    color: white;
}
/* Imitate a pressed effect for buttons */
.submit:active {
    box-shadow: 0px 0px #9624ed;
    top: 4px;
}


.cancel {
    background: #00ddeb;
    box-shadow: 0px 5px #028d96;
    color: white;
}

.cancel:hover {
    background: #01d1de;
    box-shadow: 0px 4px #006066;
    color: white;
}

/* Imitate a pressed effect for buttons */
.cancel:active {
    top: 4px;
    box-shadow: 0px 0px #d3545d;
}

.info {
    background: #FFBBDA;
    box-shadow: 0px 10px #FEE5E0;
    color: white;
}

.info-green {
    background: #55CBCD;
    box-shadow: 0px 10px #A2E1DB;
    color: white;
}

.info:hover {
    background: #FFD700;
    box-shadow: 0px 15px #e0bd00;
    color: white;
}

.info-green:hover {
    background: #FFD700;
    box-shadow: 0px 15px #e0bd00;
    color: white;
}

/* Imitate a pressed effect for buttons */
.info:active {
    top: 4px;
    box-shadow: 0px 0px #d3545d;
}

.info-green:active {
    top: 4px;
    box-shadow: 0px 0px #d3545d;
}

.font_dice {
    font-size: 3rem;
}

.border-gold {
    border-width: 2px;
    border-radius: 10px;
    border-color: goldenrod;
}

.text-align-center-pd10 {
    text-align: center;
    padding: 5px;
}

.square-no-bg-purple {
    border-width: 5px;
    border-color: #bf6bff;
    box-shadow: 5px 10px 10px 0px #9624ed;
    border-radius: 5px;
    padding: 5px;
    margin: 15px;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 350px;
    max-width: 650px;
}

.vertical-align-center {
    justify-items: center;
    align-items: center;
    vertical-align: center;
    text-align: center;
}

.bonus-text {
    text-shadow: 3px -4px #b000b6;
    font-size: 45px;
}

.bonus-text-swap {
    text-shadow: 3px -4px #e0bd00;
    font-size: 45px;
}

.notice-text-swap {
    text-shadow: 1px 1px #d535db;
    /*color: #d535db;*/
}

.button-padding {
    padding: 5px;
    margin: 5px;
}

.dot {
    height: 25px;
    width: 25px;
    background-color: #ffffff;
    border-radius: 50%;
    display: inline-block;
}

.flex-space-around{
    display: flex;
    justify-content:space-around;
}

.label-text-swap1 {
    /*text-shadow: 3px -4px #FFFFFF;*/
    font-size: 25px;
    color: #FAAE9F;
}

.label-text-swap2 {
    text-shadow: 3px 3px #FFFFFF;
    font-size: 25px;
    color: #FAAE9F;
}

.btn-round {
    float: left;
    position: relative;
    top: 0;
    cursor: pointer;
    background: white;
    border-radius: 50px;
    /*box-shadow: 0px 4px rgba(0, 0, 0, 0.2);*/
    margin: 0 7px 11px 0;
    color: #888;
    text-align: center;
    user-select: none;
    transition: all 0.2s ease;
}
