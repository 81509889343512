.container_box
{
  -webkit-perspective: 500px;
  -moz-perspective: 500px;
  -ms-perspective: 500px;
  perspective: 500px;
}
.box {
  display: block;
  width: 100px;
  height: 100px;
  margin: 20px auto;
  -webkit-transform-style: preserve-3d;
  -webkit-transition: transform 350ms;
  -webkit-animation: spin 4s infinite linear;
  -moz-transform-style: preserve-3d;
  -moz-transition: transform 350ms;
  -moz-animation: spin 4s infinite linear;
  -ms-transform-style: preserve-3d;
  -ms-transition: transform 350ms;
  -ms-animation: spin 4s infinite linear;
  transform-style: preserve-3d;
  transition: transform 350ms;
  animation: spin 4s infinite linear;
}

.box:before, .box:after, .box i, .box i:before, .box i:after {
  content: '';
  display: block;
  width: 100px;
  height: 100px;
  position: absolute;
  -webkit-animation: lighting 2s infinite linear;
  -moz-animation: lighting 2s infinite linear;
  -ms-animation: lighting 2s infinite linear;
  animation: lighting 2s infinite linear;
}

.box:before {
  -webkit-transform: rotateY(90deg) translate3D(-50px,0,50px);
  -moz-transform: rotateY(90deg) translate3D(-50px,0,50px);
  -ms-transform: rotateY(90deg) translate3D(-50px,0,50px);
  transform: rotateY(90deg) translate3D(-50px,0,50px);
  background-color: #FFD700;
  content:'';/*url(img_welcome_100x100.png)*/
}

.box:after {
  -webkit-transform: rotateY(90deg) rotateZ(180deg) translate3D(50px,0,-50px);
  -moz-transform: rotateY(90deg) rotateZ(180deg) translate3D(50px,0,-50px);
  -ms-transform: rotateY(90deg) rotateZ(180deg) translate3D(50px,0,-50px);
  transform: rotateY(90deg) rotateZ(180deg) translate3D(50px,0,-50px);
  background-color: #FFD700;
  content:'';/*url(logo_100x100.png)*/
}

.box i {
  -webkit-transform-style: preserve-3d;
  -webkit-transform: translate3D(0,0,100px);
  -webkit-animation-delay: 1s;
  -moz-transform-style: preserve-3d;
  -moz-transform: translate3D(0,0,100px);
  -moz-animation-delay: 1s;
  -ms-transform-style: preserve-3d;
  -ms-transform: translate3D(0,0,100px);
  -ms-animation-delay: 1s;
  transform-style: preserve-3d;
  transform: translate3D(0,0,100px);
  animation-delay: 1s;
  background-color: #000000;
  justify-content: center;
  display: flex;
  align-items: center;
}

.box i:before {
  -webkit-transform: rotate(180deg) translate3D(0,0,-100px);
  -webkit-animation-delay: 1s;
  -moz-transform: rotate(180deg) translate3D(0,0,-100px);
  -moz-animation-delay: 1s;
  -ms-transform: rotate(180deg) translate3D(0,0,-100px);
  -ms-animation-delay: 1s;
  transform: rotate(180deg) translate3D(0,0,-100px);
  animation-delay: 1s;
  background-color: #000000;
}

.box i:after {
  /*background-color: #999999;*/
  /*box-shadow: 0 0 30px rgba(0,0,0,1);*/
  opacity: 0.5;
  -webkit-transform: rotateX(90deg) translate3D(0,-50px,-100px);
  -webkit-filter: blur(10px);
  -webkit-animation: none;
  -moz-transform: rotateX(90deg) translate3D(0,-50px,-100px);
  -moz-filter: blur(10px);
  -moz-animation: none;
  -ms-transform: rotateX(90deg) translate3D(0,-50px,-100px);
  -ms-filter: blur(10px);
  -ms-animation: none;
  transform: rotateX(90deg) translate3D(0,-50px,-100px);
  filter: blur(10px);
  animation: none;
}

.frame-padding {
  margin-left: 10px;
  margin-right: 10px;
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotateY(0deg) translateZ(-50px); }
  100% { -webkit-transform: rotateY(360deg) translateZ(-50px); }
}
@-webkit-keyframes lighting {
  0% { box-shadow: inset 100px 0 150px rgba(0,0,0,0.4), inset 0 0 150px rgba(255,255,0255,0.3); }
  100% { box-shadow: inset 0 0 150px rgba(0,0,0,0.4), inset -100px 0 150px rgba(255,255,0255,0.3); }
}

@-moz-keyframes spin {
  0% { -moz-transform: rotateY(0deg) translateZ(-50px); }
  100% { -moz-transform: rotateY(360deg) translateZ(-50px); }
}
@-moz-keyframes lighting {
  0% { box-shadow: inset 100px 0 150px rgba(0,0,0,0.4), inset 0 0 150px rgba(255,255,0255,0.3); }
  100% { box-shadow: inset 0 0 150px rgba(0,0,0,0.4), inset -100px 0 150px rgba(255,255,0255,0.3); }
}

@-ms-keyframes spin {
  0% { -ms-transform: rotateY(0deg) translateZ(-50px); }
  100% { -ms-transform: rotateY(360deg) translateZ(-50px); }
}
@-ms-keyframes lighting {
  0% { box-shadow: inset 100px 0 150px rgba(0,0,0,0.4), inset 0 0 150px rgba(255,255,0255,0.3); }
  100% { box-shadow: inset 0 0 150px rgba(0,0,0,0.4), inset -100px 0 150px rgba(255,255,0255,0.3); }
}

@keyframes spin {
  0% {transform: rotateY(0deg) translateZ(-50px); }
  100% {transform: rotateY(360deg) translateZ(-50px); }
}
@keyframes lighting {
  0% { box-shadow: inset 100px 0 150px rgba(0,0,0,0.4), inset 0 0 150px rgba(255,255,0255,0.3); }
  100% { box-shadow: inset 0 0 150px rgba(0,0,0,0.4), inset -100px 0 150px rgba(255,255,0255,0.3); }
}